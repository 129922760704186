// Generated by Framer (b084a7c)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IHLRzLhk0"];

const variantClassNames = {IHLRzLhk0: "framer-v-1bhezie"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IHLRzLhk0", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mhLaP", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1bhezie", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IHLRzLhk0"} ref={ref} style={{borderBottomLeftRadius: 25, borderBottomRightRadius: 25, borderTopLeftRadius: 25, borderTopRightRadius: 25, ...style}}><motion.div className={"framer-wui2lo"} data-framer-name={"Gradient"} layoutDependency={layoutDependency} layoutId={"zbls8kxu6"} style={{background: "linear-gradient(141deg, rgb(68, 204, 255) 5.957620525461149%, hsl(314, 96%, 52%) 100%)", filter: "blur(10px)", WebkitFilter: "blur(10px)"}}/><motion.div className={"framer-v3mpd0"} data-framer-name={"SlideshowCard"} layoutDependency={layoutDependency} layoutId={"MbmqaT6wU"} style={{backgroundColor: "rgb(242, 242, 242)", borderBottomLeftRadius: 23, borderBottomRightRadius: 23, borderTopLeftRadius: 23, borderTopRightRadius: 23}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mhLaP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mhLaP .framer-2a08n { display: block; }", ".framer-mhLaP .framer-1bhezie { height: 407px; overflow: hidden; position: relative; width: 320px; will-change: var(--framer-will-change-override, transform); }", ".framer-mhLaP .framer-wui2lo { aspect-ratio: 1 / 1; bottom: -46px; flex: none; height: var(--framer-aspect-ratio-supported, 498px); left: -89px; overflow: hidden; position: absolute; right: -89px; }", ".framer-mhLaP .framer-v3mpd0 { bottom: 2px; flex: none; left: 2px; overflow: hidden; position: absolute; right: 2px; top: 2px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 407
 * @framerIntrinsicWidth 320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerC9YkJNGFn: React.ComponentType<Props> = withCSS(Component, css, "framer-mhLaP") as typeof Component;
export default FramerC9YkJNGFn;

FramerC9YkJNGFn.displayName = "ShowcaseCard";

FramerC9YkJNGFn.defaultProps = {height: 407, width: 320};

addFonts(FramerC9YkJNGFn, [])